<template>
  <div style="position: relative">
    <div
      class="width bg_fa flex bewteen aline_center center size_20"
      style="height: 120px"
    >
      <div style="width: 390px">
        <div>{{ cnt.quota ? cnt.quota : 0 }}万</div>
        <div class="mat_20">申请总额度</div>
      </div>
      <div style="width: 390px">
        <div>{{ cnt.accept_total ? cnt.accept_total : 0 }}万</div>
        <div class="mat_20">已授信</div>
      </div>
      <div style="width: 390px">
        <div>{{ cnt.no_accept_num ? cnt.no_accept_num : 0 }}条</div>
        <div class="mat_20">待授信</div>
      </div>
      <div style="width: 390px">
        <div>{{ cnt.demand_num ? cnt.demand_num : 0 }}次</div>
        <div class="mat_20">收到的融资需求</div>
      </div>
    </div>

    <!-- <div class="bor_b flex mat_30" style="line-height: 50px; font-size: 14px; cursor: pointer;">
      <div style="margin-right: 100px; color: #808080;" @click='changeTags(1)'>
        <span :class="{main_text_color: menu == 1}">全部</span>
        <div :class="{getDamand_nav: menu == 1}"></div>
      </div>
      <div style="margin-right: 100px; color: #808080;" @click='changeTags(2)'>
        <span :class="{main_text_color: menu == 2}">进行中</span>
        <div :class="{getDamand_nav: menu == 2}"></div>
      </div>
      <div style='color: #808080;' @click='changeTags(3)'>
        <span :class="{main_text_color: menu == 3}">已完成</span>
        <div :class="{getDamand_nav: menu == 3}"></div>
      </div>
    </div> -->

    <el-tabs v-model="activeName" @tab-click="changeTags(activeName)">
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="进行中" name="2"></el-tab-pane>
      <el-tab-pane label="已完成" name="3"></el-tab-pane>
    </el-tabs>

    <div class="flex bewteen aline_center center wrap" style="padding: 20px 0">
      <el-form ref="form" :model="form" label-width="80px" class="flex">
        <div class="flex bewteen aline_center center">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
          >
            <div>
              <el-form-item label="搜索：">
                <el-input
                  v-model="form.keyword"
                  placeholder="请输入产品名称"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="拟融资额度（万元）：" label-width="210px">
                <el-col :span="11">
                  <el-input
                    v-model="form.min_quota"
                    placeholder="请输入数字"
                  ></el-input>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                  <el-input
                    v-model="form.max_quota"
                    placeholder="请输入数字"
                  ></el-input>
                </el-col>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="申请时间：" label-width="150px">
                <el-col :span="11">
                  <el-date-picker
                    clearable
                    type="date"
                    placeholder="开始日期"
                    v-model="form.startTime"
                    style="width: 100%"
                    @input="clearStartTime"
                  ></el-date-picker>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                  <el-date-picker
                    clearable
                    type="date"
                    placeholder="结束日期"
                    v-model="form.endTime"
                    style="width: 100%"
                    @input="clearEndTime"
                  ></el-date-picker>
                </el-col>
              </el-form-item>
            </div>
            <div style="text-align: left">
              <el-form-item>
                <el-button type="primary" @click.native="searchData"
                  >搜索</el-button
                >
              </el-form-item>
            </div>
          </div>

          <!-- <div style="width:30%">
            <el-form-item label="资金用途：">
              <el-select v-model="form.used" placeholder="请选择" clearable @clear='clearValue' style="width:100%">
                 <el-option :value="item.value" v-for='item in opaction' @click.native='getCapitalComment(item.id)'></el-option>
              </el-select>
            </el-form-item>
          </div> -->
        </div>
      </el-form>
    </div>

    <el-table
      :data="listsItem"
      style="width: 100%"
      :header-cell-style="{ background: '#e5f3ff', color: '#1A7FD7' }"
    >
      <el-table-column prop="mark" label="编号"> </el-table-column>
      <el-table-column prop="company_name" label="企业名称"> </el-table-column>
      <el-table-column prop="region_name" label="地区"> </el-table-column>
      <el-table-column prop="quota" label="拟融资额度（万元）" class-name="red">
      </el-table-column>
      <el-table-column prop="capital_comment" label="资金用途">
      </el-table-column>
      <el-table-column prop="cate_name" label="担保方式" width="80px">
      </el-table-column>
      <el-table-column prop="accept_time" label="匹配时间"> </el-table-column>
      <el-table-column prop="status" label="当前状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 2">待受理</span>
          <span v-if="scope.row.status == 3">待预授信</span>
          <span v-if="scope.row.status == 4">待授信</span>
          <span v-if="scope.row.status == 5">拒绝授信</span>
          <span v-if="scope.row.status == 6">已授信</span>
          <span v-if="scope.row.status == 7">已完成</span>
          <span v-if="scope.row.status == 8">待上传</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="accept_quota"
        label="授信额度（万元）"
        class-name="blue"
        width="80px"
      ></el-table-column>
      <el-table-column
        prop="contacts"
        label="联系人"
        width="80px"
      ></el-table-column>
      <el-table-column prop="mobile" label="联系电话"></el-table-column>
      <el-table-column prop="address" label="操作" width="220px">
        <template slot-scope="scope">
          <span
            class="success_font"
            @click="companyInfo(scope.row.company_user_id)"
            >企业信息</span
          >
          <div v-if="scope.row.status == 2" class="showBtn">
            <span class="success_font" @click="submitData(scope.row.id, 3)"
              >受理</span
            >
          </div>

          <div v-if="scope.row.status == 3">
            <span class="success_font" @click="preSubmitData(scope.row.id, 8)"
              >开始预授信</span
            >
          </div>

          <div v-if="scope.row.status == 4">
            <span class="success_font" @click="submitCredit(scope.row.id, 6)"
              >授信</span
            >
            <span class="info_font" @click="submitReturn(scope.row.id, 5)"
              >拒绝授信</span
            >
            <span
              style="margin: 10px"
              class="success_font"
              @click="showDetil(scope.row.id, 8)"
              >企业资料</span
            >
          </div>

          <div v-if="scope.row.status == 5">
            <span class="success_font">无</span>
          </div>

          <div v-if="scope.row.status == 6">
            <span class="success_font" @click="submitData(scope.row.id, 7)"
              >点击完成
            </span>
          </div>

          <div v-if="scope.row.status == 7">
            <span class="success_font">无</span>
          </div>
          <div v-if="scope.row.status == 8">
            <span class="success_font">无</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="financialproducts_list_reportscreen">
      <div class="financialproducts_list_footer">
        <div class="financialproducts_list_mar">
          <app-mypaginations
            :pagSize="paginations"
            :allData="goodList"
            @setHandleCurrent="handleCurrent"
            @setHandleSize="handleSize"
            @setPage="setPage"
            @setSize="setSize"
          />
        </div>
      </div>
    </div>

    <!-- 企业信息 -->
    <el-dialog title="企业信息" :visible.sync="dialog" width="80%">
      <div class="prodictContent">
        <div class="demand_line">
          <div class="demand_flex">
            <span class="demand_text">企业名称：</span>
            <span class="demand_con">{{ userInfo.enterprise_name }}</span>
          </div>

          <div class="demand_flex">
            <span class="demand_text">统一社会信用代码：</span>
            <span class="demand_con">{{ userInfo.credit_code }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex">
            <span class="demand_text">法人代表：</span>
            <span class="demand_con">{{ userInfo.legal_person }}</span>
          </div>

          <div class="demand_flex">
            <span class="demand_text">法人联系电话：</span>
            <span class="demand_con">{{ userInfo.legal_person_tel }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex">
            <span class="demand_text">企业联系人：</span>
            <span class="demand_con">{{ userInfo.enterprise_name }}</span>
          </div>

          <div class="demand_flex">
            <span class="demand_text">企业联系电话：</span>
            <span class="demand_con">{{ userInfo.enterprise_tel }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex">
            <span class="demand_text">企业联系微信:</span>
            <span class="demand_con">{{
              userInfo.enterprise_contacts_wechat
            }}</span>
          </div>

          <div class="demand_flex">
            <span class="demand_text">企业介绍：</span>
            <span class="demand_con">{{ userInfo.introduce }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex">
            <span class="demand_text">主营业务：</span>
            <span class="demand_con">{{ userInfo.business }}（万元）</span>
          </div>

          <div class="demand_flex">
            <span class="demand_text">经营范围：</span>
            <span class="demand_con">{{ userInfo.business_scope }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">注册时间：</span>
            <span class="demand_con">{{ userInfo.register_time }}</span>
          </div>
        </div>
        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">注册资金：</span>
            <span class="demand_con">{{ userInfo.register_funds }}</span>
          </div>
        </div>
        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">单位在职人数:</span>
            <span class="demand_con">{{ userInfo.employees_num }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">注册地址：</span>
            <span class="demand_con">{{ userInfo.register_addr }}</span>
          </div>
        </div>
        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">注册详情地址：</span>
            <span class="demand_con">{{ userInfo.register_address }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">企业所在地:</span>
            <span class="demand_con">{{ userInfo.enterprise_address }}</span>
          </div>
        </div>

        <div class="demand_line">
          <div class="demand_flex1">
            <span class="demand_text">所属行业:</span>
            <span class="demand_con">{{ userInfo.belong_server }}</span>
          </div>
        </div>

        <!-- <div class="demand_line">
          <el-table :data="userInfo.target" style="width: 100%">
            <el-table-column prop="year" label="年份" width="180">
            </el-table-column>
            <el-table-column
              prop="total_inv"
              label="总资产（万元）"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="sale_rev" label="销售收入（万元）">
            </el-table-column>
            <el-table-column prop="net_profit" label="净利润（万元）">
            </el-table-column>
            <el-table-column prop="cash_flow" label="经营性现金流量（万元）">
            </el-table-column>
            <el-table-column prop="net_flow" label="经营性净流量（万元）">
            </el-table-column>
            <el-table-column prop="flow_radio" label="流量比率（%）">
            </el-table-column>
          </el-table>
        </div> -->

        <div class="demand_line2">
          <div class="demand_text" style="margin-bottom: 12px">
            企业营业执照：
          </div>
          <div>
            <el-image
              style="width: 132px; height: 132px"
              :src="userInfo.business_license_img"
              fit="fit"
              :preview-src-list="[userInfo.business_license_img]"
            ></el-image>
          </div>
        </div>
        <div class="demand_line2">
          <div class="demand_text" style="margin-bottom: 12px">企业logo：</div>
          <div>
            <el-image
              style="width: 132px; height: 132px"
              :src="userInfo.logo_img"
              fit="fit"
              :preview-src-list="[userInfo.logo_img]"
            ></el-image>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 预授信 -->
    <el-dialog
      title="企业提交资料"
      :visible.sync="preSubmitDialogVisible"
      width="60%"
    >
      <el-button
        size="mini"
        type=""
        @click="addDomain()"
        style="position: absolute; right: 30px"
        >添加文件类型</el-button
      >
      <div v-for="(item, index) in file_type" :key="index" class="addFile">
        <el-form :rules="explainRules" :ref="`file_Ref${index}`" :model="item">
          <el-form-item prop="explain_id" :label="'类型' + (index + 1)">
            <el-select v-model="item.explain_id" placeholder="请选择文件类型">
              <el-option
                v-for="item in optionList"
                :key="item.explain_id"
                :label="item.explain_name"
                :value="item.explain_id"
              >
              </el-option>
            </el-select>

            <el-button size="mini" type="danger" @click="delFile(index)"
              >删除</el-button
            >
          </el-form-item>
          <!-- <div ><span >类型{{ index + 1 }}:</span> -->
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="preSubmitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPreFile()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 企业信息 -->
    <el-dialog title="企业信息" :visible.sync="detilDialogVisible" width="60%">
      <!-- 上传 -->
      <div v-for="(item, index) in fileTitle" :key="index">
        <div class="titleSty">{{ item.explain_name }}</div>
        <!-- <div class="webproductapply_form_document">
							<div v-if="!(item.explain_name===null)" class="download">
                <div v-for="(item,index) in fileList" :key="index" >
                  <el-tooltip class="item" effect="dark" content="下载" placement="top">
      <el-button style="margin-left: 20px;!important"><a style="color:black" :href="`${item.path}`" target="downloadFile">{{item.file_name}}</a>
</el-button>
    </el-tooltip>
							</div>
						</div>
						</div> -->
        <div class="prodictContent">
          <div v-for="(items, index) in fileList" :key="index">
            <div class="upload_line">
              <div v-if="items.require_id == item.id">
                <div class="upload_flex">
                  <div class="upload_con">{{ items.file_name }}</div>
                  <a :href="`${items.path}`" download class="upload_text"
                    >下载文件</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detilDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="detilDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MyPaginations from "@/components/MyPaginations/mypaginations.vue";
export default {
  data() {
    return {
      menu: 1,
      form: {
        keyword: "",
        min_quota: "",
        max_quota: "",
        startTime: "",
        endTime: "",
        used: "",
      },
      opaction: [
        {
          value: "产品研发",
          id: 1,
        },
        {
          value: "资金周转",
          id: 2,
        },
        {
          value: "其他",
          id: 3,
        },
      ],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      goodList: [],
      listsItem: [],
      count: 0,
      cnt: {},
      capital_comment: "",
      activeName: "1",
      dialog: false,
      userInfo: {},
      preSubmitDialogVisible: false,
      file_type: [
        {
          sort: 1,
          file_explain: "",
          explain_id: undefined,
        },
      ],
      optionList: [],
      value: "",
      textarea: "",
      explain: "",
      explainId: "",
      explainRules: {
        explain_id: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
      },
      detilDialogVisible: false,
      fileTitle: [],
      fileList: [],
    };
  },
  methods: {
    loadFile(item) {
      console.log(item, "item");
    },
    // 企业信息
    showDetil(val) {
      const eleToken = localStorage.getItem("eleToken");

      this.detilDialogVisible = true;

      this.$get("/file_require_list", { demand_id: val, token: eleToken }).then(
        (res) => {
          this.fileTitle = res.data.result;
        }
      );

      this.$get("/demand_file_list", { demand_id: val, token: eleToken }).then(
        (res) => {
          console.log(res.data.result);
          this.fileList = res.data.result;
          console.log(this.fileList, "fileList");
          //   res.data.result.forEach(item=>{
          // this.$set(this.fileList,this.fileList.length,{
          //   name:item.file_name,
          //   url:item.path
          // })
          // })
        }
      );
    },

    // showPre(load){

    // },
    // 添加授信
    addDomain() {
      this.$set(this.file_type, this.file_type.length, {
        sort: this.file_type.length + 1,
        file_explain: "",
        explain_id: "",
      });
    },
    // 提交授信
    async submitPreFile() {
      try {
        for (let index = 0; index < this.file_type.length; index++) {
          await this.$refs[`file_Ref${index}`][0].validate();
        }
        let data = [];
        const eleToken = localStorage.getItem("eleToken");
        if (this.explain === "") {
          data = this.file_type;
        } else {
          data = JSON.parse(JSON.stringify(this.file_type));
          data.push({
            sort: this.file_type.length + 1,
            file_explain: this.explain,
            explain_id: "",
          });
        }
        this.$post("/file_require_add", {
          explain: data,
          token: eleToken,
          demand_id: this.explainId,
        }).then((res) => {
          console.log(res, "res");
          if (res.status === 200) {
            this.$message.success("授信成功!");
            this.getData();
            this.preSubmitDialogVisible = false;
          } else {
            this.$message.error("授信失败！");
          }
        });
      } catch (error) {
        this.$message.error("请检查必填项");
      }
    },

    delFile(index) {
      this.file_type.splice(index, 1);
    },
    companyInfo(id) {
      let data = {
        company_user_id: id,
        token: localStorage.eleToken ? localStorage.eleToken : "",
      };

      this.$post("/company_info", data).then((res) => {
        this.userInfo = res.data.data;
        this.dialog = true;
      });
    },
    submitCredit(id, type) {
      this.$prompt("请输入额度(万元)", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
        inputErrorMessage: "输入额度不正确",
      })
        .then(({ value }) => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : "",
            id,
            status: type,
            accept_quota: value,
          };

          this.$post("demand_state", data).then((res) => {
            let type = res.data.status == 1 ? "success" : "error";

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    submitReturn(id, type) {
      this.$prompt("是否拒绝授信?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "请输入拒绝授信原因",
        inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
        inputErrorMessage: "拒绝授信原因不能为空",
      })
        .then(({ value }) => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : "",
            id,
            status: type,
            comment: value,
          };

          this.$post("demand_state", data).then((res) => {
            let type = res.data.status == 1 ? "success" : "error";

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    submitData(id, type) {
      let font =
        type == 3
          ? "是否受理?"
          : type == 4
          ? "是否开始预授信?"
          : type == 5
          ? "是否拒绝授信?"
          : type == 7
          ? "是否完成?"
          : "";

      this.$confirm(font, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : "",
            id,
            status: type,
          };

          this.$post("demand_state", data).then((res) => {
            let type = res.data.status == 1 ? "success" : "error";

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 开始预授信
    preSubmitData(val, index) {
      this.explainId = val;
      this.preSubmitDialogVisible = true;
      const eleToken = localStorage.getItem("eleToken");
      this.$get("/explain_lst", { token: eleToken, type: 2 }).then((res) => {
        this.optionList = res.data.result;
      });
    },
    changeTags(num) {
      this.menu = num;

      this.searchData();
    },
    clearEndTime(value) {
      if (value == null) {
        this.form.endTime = "";
      }
    },
    clearStartTime(value) {
      if (value == null) {
        this.form.startTime = "";
      }
    },
    clearValue() {
      this.form.used = "";
    },
    searchData() {
      this.paginations.page_index = 1;

      this.getData();
    },
    getCapitalComment(id) {
      this.capital_comment = id;
    },
    setPage(data) {
      this.paginations.page_index = data;
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.goodList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    getData() {
      let data = {
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        token: localStorage.eleToken ? localStorage.eleToken : "",
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        startTime:
          this.form.startTime == "" ? "" : this.$turnTime(this.form.startTime),
        endTime:
          this.form.endTime == "" ? "" : this.$turnTime(this.form.endTime),
        status: "",
        region_id: "",
        capital_comment: this.form.used,
        name: this.form.keyword,
        group: this.menu,
      };

      this.$get("/demand_info", data).then((res) => {
        if (!res.data.result) {
          this.goodList = [];
          this.count = 0;
        } else {
          this.cnt = res.data.result.cnt;
          this.goodList =
            res.data.result.info.length == 0 ? [] : res.data.result.info;
          console.log(this.goodList, 635);
          this.goodList.map((item) => {
            if (item.status == 2) {
              item.quota = "xxx";
              item.company_name = "xxx";
              item.region_name = "xxx";
              item.capital_comment = "xxx";
              item.cate_name = "xxx";
              item.contacts = "xxx";
              item.mobile = "xxx";
            }
          });
          this.count = res.data.count ? res.data.count : 0;
        }
        this.resetPaginations();
      });
    },
  },
  mounted() {
    this.getData();
    console.log(this.form);
  },
  components: {
    "app-mypaginations": MyPaginations,
  },
  created() {
    console.log(661, this.userInfo);
  },
};
</script>

<style scoped>
.getDamand_nav {
  width: 40px;
  height: 4px;
  background: #0d8eff;
  border-radius: 2px;
}

/*分页*/
.financialproducts_list_reportscreen {
  position: absolute;
  bottom: -11%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.financialproducts_list_footer {
  min-width: 402px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.financialproducts_list_mar {
  width: 100%;
}

.el-pagination {
  display: flex;
}

.el-pagination .el-select .el-input {
  height: 100%;
}

.el-select {
  height: 100%;
}

.el-pagination__jump {
  display: flex !important;
}

/deep/.red {
  color: #ff9d00;
}

/deep/.blue {
  color: #0d8eff;
}

/deep/.el-tabs__nav-wrap::after {
  height: 1px;
}

.demand_line {
  display: flex;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid #d6d6d6;
}

.demand_line2 {
  padding: 18px 0;
}

.demand_flex {
  width: 50%;
  display: flex;
  align-items: center;
}

.demand_flex1 {
  width: 100%;
}

.demand_text {
  font-size: 16px;
  color: #1a1a1a;
}

.prodict_con {
  font-size: 16px;
  color: #616161;
}
.addFile {
  margin: 25px;
}
.addFile .el-button {
  margin-left: 20px;
}
.webproductapply_form_document {
  width: 90%;
  display: flex;
}
.download {
  flex: 1;
  display: flex;
}
.download a {
  flex: 1;
}
.upload_flex {
  width: 31.5rem;
  margin-top: 20px;
  padding: 18px 0;
  border-bottom: 1px solid #d6d6d6;
}

.upload_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload_text {
  float: right;
  width: 60px;
  color: #6dc1ff;
}
.titleSty {
  font-size: 16px;
  font-weight: 700;
  margin: 10px;
  width: 100%;
}
</style>
